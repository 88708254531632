export const GAMES = 'https://getmega.onelink.me/shOD/Others'

export const CARDS = 'https://getmega.onelink.me/shOD/Cards'

export const CASUAL = 'https://getmega.onelink.me/shOD/CasualP'

export const TRIVIA = 'https://getmega.onelink.me/shOD/TriviaP'

export const DOWNLOAD =
  'https://getmega.onelink.me/shOD/OrganicWebsiteInstallApp'

export const POKER = 'https://www.getmega.com/poker/ '

export const POKER_LEARN = 'https://getmega.onelink.me/shOD/PokerLearn'

export const POKER_BOOKS = 'https://getmega.onelink.me/shOD/PokerBooks1'

export const POKER_HANDS = 'https://getmega.onelink.me/shOD/PokerHands1'

export const POKER_TERMS = 'https://getmega.onelink.me/shOD/PokerTerms1'

export const POKER_STRATEGY = 'https://getmega.onelink.me/shOD/PokerStrategy1'

export const RUMMY = 'https://getmega.onelink.me/shOD/RummyP'

export const RUMMY_LEARN = 'https://getmega.onelink.me/shOD/RummyLearn'

export const CARROM = 'https://getmega.onelink.me/shOD/Carrom'

export const POOL = 'https://getmega.onelink.me/shOD/Pool'

export const HOPPOP = 'https://getmega.onelink.me/shOD/CasualChineseCheckers'

export const ABCRUMMY = 'https://getmega.onelink.me/shOD/CasualABCRummy'

export const DOTSDASH = 'https://getmega.onelink.me/shOD/CasualDotsDash'

export const WARSHIP = 'https://getmega.onelink.me/shOD/CasualWarship'

export const GKGURU = 'https://getmega.onelink.me/shOD/TriviaGK'

export const ONE23 = 'https://getmega.onelink.me/shOD/Trivia123Maths'

export const PICME = 'https://getmega.onelink.me/shOD/TriviaPicme'

export const HOME_PAGE = 'https://getmega.onelink.me/shOD/BrandHomepage'

export const CHESS_LEARN = 'https://getmega.onelink.me/shOD/ChessLearn'

export const CRICKET_LEARN = 'https://getmega.onelink.me/shOD/CricketLearn'

export const CARROM_LEARN = 'https://getmega.onelink.me/shOD/CarromLearn'

export const POOL_LEARN = 'https://getmega.onelink.me/shOD/PoolLearn'

export const LEVEL2_PAGE = 'https://getmega.onelink.me/shOD/CompetitorsCompApps'

export const EARN_MONEY_PAGE = 'https://getmega.onelink.me/shOD/EarnMoneyy'

export const COMPARE = 'https://getmega.onelink.me/shOD/CompetitorsCompare'

export const GAMBLING = 'https://getmega.onelink.me/shOD/Gamblingg'

export const LISTINGS = 'https://getmega.onelink.me/shOD/Listings'

export const INSTALL_PAGE = 'https://getmega.onelink.me/shOD/BrandInstall'

export const LATEST_RC = 'https://www.getmega.com/latest-rc'

export const GENERIC_SOCIAL_APK = 'https://www.getmega.com/latest-social'
export const GENERIC_REFER_APK = GENERIC_SOCIAL_APK
export const GENERIC_APK = GENERIC_SOCIAL_APK

export const MEGA_CAMPAIGNS_TEMPLATE_ONELINK =
  'https://getmega.onelink.me/shOD/'
