import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Blogs from '../components/Blogs/Blogs'
import EARN_MONEY_TAGS from '../earnMoney-tags'
import { EARN_MONEY } from '../components/internal-links'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { LATEST_RC } from '../components/download-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Earn Money',
    url: EARN_MONEY,
  },
]

const LearnEarnMoney: React.FC = () => {
  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={LATEST_RC}>
      <SEO
        title="Make Money Online: Best sites, methods, and tips to earn | Mega"
        description="Each one of us wants to have a passive source of income, right? Yes! Get to know the best ways, methods &amp; strategies to make money online. Read Now!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="earn-money"
        tags={EARN_MONEY_TAGS}
        prefix={`/${process.env.GATSBY_CMS_EARN_MONEY_PREFIX}` || '/'}
        title={
          <>
            Earn Money - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnEarnMoney
